import api from 'src/providers/api'
import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useSteps } from 'src/hooks'
import { Box, Flex, StepsContainer, Text } from 'src/components/ui'

const Input = styled.textarea`
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: #303030;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 2px;
`

const Label = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 900;
  color: #303030;
  line-height: 24px;
  margin-bottom: 8px;
`

const Counter = styled.span`
  position: absolute;
  right: 0;
  top: 102%;
  color: #d2d2d2;
  font-size: 11px;
`

const ActionPlan = () => {
  const [options, setOptions] = React.useState([])

  const { setNextAction } = useSteps()

  const handleChangeInput = React.useCallback(
    (id, text) => {
      if (text.length <= 140) {
        const optionIndex = options.findIndex((option) => option.id === id)

        const option = options[optionIndex]

        options[optionIndex] = { ...option, action_plan: text }

        setOptions([...options])
      }
    },
    [options],
  )

  const handleSubmit = React.useCallback(async () => {
    if (options.some(({ action_plan }) => !action_plan)) {
      toast.warn('Tu deves elencar ações/habitos para todas as virtudes')

      return Promise.reject(new Error('All fields are required'))
    }

    await api.put(
      '/virtues',
      options.map(({ action_plan, id }) => ({ action_plan, id })),
    )
  }, [options])

  React.useEffect(() => {
    api.get('/virtues', { params: { onlyChosen: true, orderByPriority: true } }).then(({ data }) => setOptions(data))
  }, [])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Plano de Ação
        </Text>
      </Box>
      <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
        <Box flex='1'>
          <Box mb={32}>
            <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
              Hora de elencar ações/hábitos que podem serem tomados entre 1 e 30 dias para exaltar ou manter essas
              virtudes
            </Text>
            <Text color='#D2D2D2' fontSize={16}>
              Obs: seja o mais breve e objetivo possível, tu só tens até 140 caractéres (um tweet).
            </Text>
          </Box>

          <Box>
            {options.slice(0, 4).map((option) => (
              <Box key={option.id} mb={32} position='relative'>
                <Label>{option.description}</Label>
                <Input
                  name={option.id}
                  onChange={(event) => handleChangeInput(option.id, event.target.value)}
                  placeholder={option.suggestion && `Ex: ${option.suggestion}`}
                  rows='1'
                  value={options.find(({ id }) => id === option.id)?.action_plan}
                />
                <Counter>{options.find(({ id }) => id === option.id)?.action_plan?.length || 0}</Counter>
              </Box>
            ))}
          </Box>
        </Box>
        <Box flex='1' ml={[0, 48]} mt={[0]}>
          {options.slice(4).map((option) => (
            <Box key={option.id} mb={32} position='relative'>
              <Label>{option.description}</Label>
              <Input
                name={option.id}
                onChange={(event) => handleChangeInput(option.id, event.target.value)}
                placeholder={option.suggestion && `Ex: ${option.suggestion}`}
                rows='1'
                value={options.find(({ id }) => id === option.id)?.action_plan}
              />
              <Counter>{options.find(({ id }) => id === option.id)?.action_plan?.length || 0}</Counter>
            </Box>
          ))}
        </Box>
      </Flex>
    </StepsContainer>
  )
}
export default ActionPlan
