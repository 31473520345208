import api from 'src/providers/api'
import { breakpoints } from 'src/styles/theme'
import React from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Form as Unform } from '@unform/web'
import { useField } from '@unform/core'
import { useSteps } from 'src/hooks'
import { Box, Flex, Loader, StepsContainer, Text } from 'src/components/ui'
import { flexbox, layout } from 'styled-system'

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 24px;
  font-family: 'Lato', sans-serif;
  color: #303030;
  display: block;
  width: 100%;

  @media (min-width: ${breakpoints[0]}) {
    font-size: 48px;
  }
`

const FieldError = styled.span`
  color: #f33535;
  display: block;
  top: 100%;
  left: 0;
  position: absolute;
  font-size: 12px;
`

const Form = styled(Unform)(flexbox, layout)

const Field = ({ label, name, mb = 32, mask, ...props }) => {
  const inputRef = React.useRef(null)
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name)

  React.useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  return (
    <Box flex='1' mb={mb} position='relative'>
      <Input ref={inputRef} defaultValue={defaultValue} onFocus={clearError} {...props} />

      {error && <FieldError>{error}</FieldError>}
    </Box>
  )
}

const EmailConfirmation = () => {
  const formRef = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [formInitialValues, setFormInitialValues] = React.useState({})

  const { setNextAction } = useSteps()

  const handleSubmit = React.useCallback(async () => {
    formRef.current.setErrors({})

    const formData = formRef.current.getData()

    if (!formData.email) {
      toast.warn('Por favor, preencha o e-mail.')

      return Promise.reject(new Error('invalid e-mail'))
    }

    await api.put('/persons', formData)

    await api.post('/persons/generate')
  }, [])

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const voucherCode = JSON.parse(sessionStorage.getItem('voucher'))
      if (voucherCode) {
        api.get(`/vouchers/${voucherCode}`).then((response) => {
          setFormInitialValues({ email: response.data.email })
          setIsLoading(false)
        })
      }
    }
  }, [formRef])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])
  return (
    <StepsContainer>
      <Box flex='1 0 100%' mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Insira{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            Teu Email
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]} fontWeight='normal'>
          Ultimo passo do seu Mapa Biográfico
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1' flexDirection='column'>
          <Form ref={formRef} display='flex' flex='1' initialData={formInitialValues} onSubmit={() => {}}>
            <Field name='email' />
          </Form>

          <Text color='#303030' fontSize={[16, 24]} fontWeight='900' maxWidth={[475]}>
            Coloca aqui o teu email ou algum outro que deseja receber o seu{' '}
            <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
              Mapa Biográfico!
            </Text>
          </Text>
        </Flex>
      )}
    </StepsContainer>
  )
}

export default EmailConfirmation
