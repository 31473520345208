import api from 'src/providers/api'
import React from 'react'
import { ReactSortable } from 'react-sortablejs'
import styled from 'styled-components'
import { useSteps } from 'src/hooks'
import { Box, Flex, SortableOption, StepsContainer, Text } from 'src/components/ui'

const PriorizationSection = styled(Box)`
  position: relative;

  ::before {
    display: block;
    content: '';
    position: absolute;
    left: -14px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #d2d2d2;
  }

  ::after {
    display: block;
    content: '';
    position: absolute;
    width: 4px;
    top: 8px;
    bottom: 24px;
    left: -8px;
    background-color: #d2d2d2;
  }
`

const HabitPrioritization = () => {
  const [options, setOptions] = React.useState([])

  const { setNextAction } = useSteps()

  const handleSubmit = React.useCallback(async () => {
    await api.put(
      '/virtues',
      options.map(({ id }, priority_order) => ({ id, priority_order })),
    )
  }, [options])

  React.useEffect(() => {
    api.get('/virtues', { params: { onlyChosen: true, orderByPriority: true } }).then(({ data }) => setOptions(data))
  }, [])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Priorização de hábito
        </Text>
      </Box>
      <Box mb={32}>
        <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
          Vamos agora ordenar a prioridade delas.
        </Text>
        <Text color='#D2D2D2' fontSize={16}>
          Dica: Estabeleça uma ordem de prioridade de mais relevenate e importante para menos relevante e importante
        </Text>
      </Box>
      <Flex flex='1 0 100%' justifyContent='flex-start'>
        <PriorizationSection mt={[0]} pl={[16, 24]}>
          <ReactSortable animation={200} delay={2} delayOnTouchStart list={options} setList={setOptions}>
            {options.map((option) => (
              <SortableOption key={option.id} checked={option.is_chosen}>
                {option.description}
              </SortableOption>
            ))}
          </ReactSortable>
        </PriorizationSection>
      </Flex>
    </StepsContainer>
  )
}
export default HabitPrioritization
