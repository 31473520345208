import api from 'src/providers/api'
import ReactInputMask from 'react-input-mask'
import ReactSelect from 'react-select/async'
import styled from 'styled-components'
import { Form as Unform } from '@unform/web'
import { useField } from '@unform/core'
import * as yup from 'yup'
import { Box, Flex, Loader, StepsContainer, Text } from 'src/components/ui'
import { flexbox, layout } from 'styled-system'
import React, { useCallback, useEffect, useRef } from 'react'
import { usePerson, useSteps } from 'src/hooks'

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: #303030;
`

const FieldError = styled.span`
  color: #f33535;
  display: block;
  top: 100%;
  left: 0;
  position: absolute;
  font-size: 12px;
`

const FieldDescription = styled.span`
  display: block;
  color: #d2d2d2;
  line-height: 24px;
  flex: 1 0 100%;
  width: 100%;
`

const MaskedInput = styled(ReactInputMask)`
  border: none;
  border-bottom: 1px solid #d2d2d2;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: #303030;
`

const Field = ({ label, description, name, mb = 32, mask, ...props }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  return (
    <Box mb={mb} position='relative'>
      <Label>{label}</Label>
      {mask ? (
        <MaskedInput ref={inputRef} defaultValue={defaultValue} mask={mask} onFocus={clearError} {...props} />
      ) : (
        <Input ref={inputRef} defaultValue={defaultValue} onFocus={clearError} {...props} />
      )}

      {description && <FieldDescription>{description}</FieldDescription>}

      {error && <FieldError>{error}</FieldError>}
    </Box>
  )
}

export const RadioInput = styled.input`
  margin-right: 8px;
  margin-left: 8px;

  width: 8px;
  height: 8px;

  border: 1px solid;
  border-radius: 4px;

  :checked::after {
    border-color: #ff5616;
    background-color: #ff5616;
  }

  ::after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    transition: all 0.2s;
    border-radius: 4px;
    border: 1px solid;
    border-color: #d2d2d2;
    background-color: #ffffff;
    box-sizing: border-box;
  }
`

const RadioField = ({ name, label, options, description, mb = 32, ...props }) => {
  const inputRefs = useRef([])
  const { fieldName, defaultValue, registerField, error, clearError } = useField(name)

  useEffect(() => {
    registerField({
      getValue (refs) {
        const checked = refs.find((ref) => ref.checked)

        return checked ? checked.value : null
      },
      name: fieldName,
      path: 'value',
      ref: inputRefs.current,
      setValue (refs, value) {
        const item = refs.find((ref) => ref.value === value)

        if (item) {
          item.checked = true
        }
      },
    })
  }, [fieldName, registerField])

  return (
    <Flex alignItems='center' flexWrap='wrap' mb={mb} position='relative'>
      {label && <Label>{label}</Label>}
      {options.map((option, index) => (
        <Box key={option.id} alignItems='center' as='label' display='inline-flex'>
          <RadioInput
            ref={(elRef) => (inputRefs.current[index] = elRef)}
            defaultChecked={defaultValue === option.id}
            name={fieldName}
            onFocus={clearError}
            type='radio'
            value={option.id}
            {...props}
          />
          <Text as='span' fontSize={[16]} lineHeight={['24px']}>
            {option.label}
          </Text>
        </Box>
      ))}
      {description && <FieldDescription>{description}</FieldDescription>}

      {error && <FieldError>{error}</FieldError>}
    </Flex>
  )
}

const Form = styled(Unform)(flexbox, layout)

const Label = styled.label`
  font-size: 16px;
  font-weight: 900;
  color: #303030;
  line-height: 24px;
  margin-right: 12px;
`

yup.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
})

const validationSchema = yup.object().shape({
  birth_city: yup.string().required(),
  birth_date: yup.string().required(),
  birth_time: yup
    .string()
    .matches(/[0-9]{2}:[0-9]{2}/g, 'Valor inválido')
    .test('is-valid-hour', 'Valor  hora inválido', (value) => {
      const hour = +value.split(':')[0]
      const minute = +value.split(':')[1]

      return hour <= 24 && hour >= 0 && minute <= 60 && minute >= 0
    })
    .required(),
  excitation_duration: yup.string().oneOf(['yes', 'no'], 'Campo obrigatório').required(),
  first_name: yup.string().required(),
  hates_more: yup.string().required(),
  have_religion: yup.string().oneOf(['yes', 'no'], 'Você deve selecionar uma opção').required(),
  is_easily_excitable: yup.string().oneOf(['yes', 'no'], 'Campo obrigatório').required(),
  last_name: yup.string().required(),
  love_more: yup.string().required(),
  main_financial_provider_of_the_house: yup.string().required(),
  marital_status: yup.string().required(),
  parents_marital_status: yup.string().required(),
  people_living_together: yup.number().min(0).required(),
  relationship_with_people_living_together: yup.string().when('people_living_together', {
    is: 0,
    then: yup.string().notRequired(),
    otherwise: yup.string().required(),
  }),
  religion: yup.string().when('have_religion', {
    is: 'yes',
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  sex: yup.string().oneOf(['f', 'm'], 'Campo obrigatório').required(),
  third_person_name: yup.string().required(),
})

const StyledReactSelect = styled(ReactSelect)`
  flex: 1;
  max-width: 250px;

  .react-select {
    &__control {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #d2d2d2;
      box-shadow: none;

      :hover {
        border-color: #d2d2d2;
      }
    }

    &__dropdown-indicator,
    &__dropdown-indicator:hover {
      color: #ff5616;
    }

    &__menu {
      border-radius: 0;
    }
  }
`

const AsyncSelect = ({ name, label, mb = 32, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return []
          }
          return ref.select.state.value.map((option) => option.value)
        }
        if (!ref.select.state.value) {
          return ''
        }
        return ref.select.state.value.value
      },
      name: fieldName,
      ref: selectRef.current,
      setValue: (ref, value) => {
        ref.select.select.setValue(value)
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  return (
    <Box mb={mb} position='relative'>
      <Flex alignItems='center'>
        <Label>{label}</Label>
        <StyledReactSelect
          ref={selectRef}
          cacheOptions
          classNamePrefix='react-select'
          components={{ IndicatorSeparator: null, LoadingIndicator: () => <Loader /> }}
          defaultValue={defaultValue}
          loadingMessage={() => 'Buscando cidade...'}
          noOptionsMessage={({ inputValue }) =>
            inputValue ? 'Nenhum cidade econtrada' : 'Digite o nome da sua cidade'}
          placeholder=''
          {...rest}
        />
      </Flex>
      {error && <FieldError>{error}</FieldError>}
    </Box>
  )
}

const Profile = () => {
  const formRef = useRef(null)

  const { setNextAction } = useSteps()
  const { person, refetch: refetchPerson } = usePerson()

  useEffect(() => {
    if (person) {
      formRef.current.setData(person)
      formRef.current.setFieldValue('birth_city', { label: person.birth_city, value: person.birth_city })
    }
  }, [person])

  const handleSubmit = useCallback(async () => {
    try {
      formRef.current.setErrors({})

      const formData = formRef.current.getData()

      await validationSchema.validate(formData, { abortEarly: false })

      if (person) {
        await api.put('persons', formData)
        refetchPerson()
      } else {
        await api.post('persons', formData)
        refetchPerson()
      }
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        const validationErrors = err.inner.reduce((errors, { path, message }) => ({ ...errors, [path]: message }), [])

        formRef.current.setErrors(validationErrors)
      }

      throw err
    }
  }, [person, refetchPerson])

  useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  const fetchCities = async (inputValue) => {
    try {
      const { data } = await api.get('/find-city', {
        params: { q: inputValue },
      })

      return data.map((city) => city.replace(/\n/, '')).map((city) => ({ label: city, value: city }))
    } catch (e) {}
  }

  return (
    <StepsContainer>
      <Box flex='1 0 100%' mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Conhecendo{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            o Navio
          </Text>
        </Text>
      </Box>

      <Form
        ref={formRef}
        display='flex'
        flex='1'
        flexDirection={['column', 'row']}
        initialData={{ people_living_together: 1 }}
        onSubmit={handleSubmit}
      >
        <Box flex='1'>
          <Field label='Nome' name='first_name' />
          <Field label='Sobrenome' name='last_name' />
          <RadioField
            label='Sexo'
            name='sex'
            options={[
              { id: 'm', label: 'Masculino' },
              { id: 'f', label: 'Feminino' },
            ]}
          />
          <Field label='Data de nascimento' name='birth_date' type='date' />
          <Field label='Horário de nascimento' mask='99:99' name='birth_time' placeholder='Padrão 24 horas' />

          <AsyncSelect
            id='birth_city'
            isClearable
            label='Cidade de nascimento'
            loadOptions={fetchCities}
            name='birth_city'
          />

          <RadioField
            label='Possui Religião?'
            mb={0}
            name='have_religion'
            options={[
              { id: 'yes', label: 'Sim' },
              { id: 'no', label: 'Não' },
            ]}
          />
          <Field label='Qual?' name='religion' />
          <Field label='Quantas pessoas moram contigo?' mb='0px' min='0' name='people_living_together' type='number' />
          <Field label='Qual sua relação com essas pessoas?' name='relationship_with_people_living_together' />
          <Field
            label='Quem é o provedor financeiro principal da tua casa?'
            name='main_financial_provider_of_the_house'
          />
        </Box>
        <Box flex='1'>
          <Field label='Estado civil dos pais' name='parents_marital_status' />
          <Field label='Seu estado civil' name='marital_status' />
          <Field label='O que/quem tu mais amas?' name='love_more' />
          <Field label='O que/quem tu mais odeias?' name='hates_more' />

          <RadioField
            label='Quando sou afetado por algo externo ou me lembro de um afecção passada, as emoções irrompem dentro de mim pronta e intensamente?'
            name='is_easily_excitable'
            options={[
              { id: 'yes', label: 'Sim' },
              { id: 'no', label: 'Não' },
            ]}
          />
          <RadioField
            label='Uma vez surgida a emoção, ela permanece por muito tempo, de forma profunda?'
            name='excitation_duration'
            options={[
              { id: 'yes', label: 'Sim' },
              { id: 'no', label: 'Não' },
            ]}
          />
          <Field
            description='(Caso não haja essa terceira pessoa, imagine uma e lhe dê um nome)'
            label='Nome da pessoa mais intima e que respeitas'
            name='third_person_name'
          />
        </Box>
      </Form>
    </StepsContainer>
  )
}

export default Profile
