import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@reach/router'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'
import { usePerson, useSteps } from 'src/hooks'

const ComplianceAndAssertiveness = () => {
  const { person } = usePerson()
  const location = useLocation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const { setNextAction } = useSteps()
  const section = React.useMemo(() => location.pathname.split('/').pop(), [location])

  const options = React.useMemo(
    () =>
      [
        'evita conflitos, mesmo quando necessário',
        'costuma defender o comportamento inadequado de outras pessoas',
        'não costuma tirar vantagem em seus acordos',
        'sacrifica seus próprios sentimentos pelo conforto dos outros',
        'costuma esconder seus sentimentos até ficar ressentido',
        'pode ser excessivamente sentimental',
        'é polido diante do erro dos outros',
        'sente muita compaixão alheia',
        'fica profundamente sentido após conflitar com alguém',
        'poderia colher bons frutos se fosse mais competitivo',
        'às vezes é dominado por outras pessoas',
        'não sabe como lidar com pessoas más',
        'confia nas pessoas com muita facilidade',
        'costuma julgar as pessoas como boas, mesmo quando há evidências que não',
        'é fiel mesmo diante de uma falha',
        'é esquentadinho',
        'pode ser indiferente aos sentimentos dos outros',
        'não se interessa nos problemas de outras pessoas',
        'é muito desconfiado dos motivos de outras pessoas',
        'seduz as pessoas para conseguir chegar nos seus objetivos',
        'pode ser destacado e frio quando os outros estão feridos e chateados',
        'critica bastante os outros',
        'geralmente não demonstra gratidão',
        'pode ser vingativo',
        'está disposto a manipular os outros para ganho pessoal',
        'não costuma ajudar outras pessoas',
        'insulta as pessoas',
        'pode ser agressivo e dominador',
        'costuma sempre colocar-se em primeiro lugar',
        'poderia ser melhor em cooperação',
      ].map((option) => `${person?.first_name} ${option}`),
    [person?.first_name],
  )

  const handleSelectOption = (option) => {
    if (isSelectedOption(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option))
    } else {
      if (selectedOptions.length === 10) {
        toast.warn('Tu deve escolher no máximo 10 hábitos')
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const isSelectedOption = (option) => selectedOptions.some((selected) => selected === option)

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptions.length < 3) {
      toast.warn('Para continuar selecione pelo menos 3 hábitos')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.post('faults', selectedOptions, {
      params: {
        section,
      },
    })
  }, [selectedOptions, section])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/faults', { params: { section } })
      .then((response) => {
        setSelectedOptions(response.data.map(({ description }) => description))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [section])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Faltas - Conformidade e Assertividade
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Ainda como se tu foste{' '}
                <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
                  {person?.third_person_name}
                </Text>{' '}
                falando sobre ti, escolha até 10 hábitos.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Obs: escolha sendo bastante inclusivo, não se apegue à literalidade da descrição. Iremos priorizá-las
                mais tarde.
              </Text>
            </Box>
            <Box>
              {options.slice(0, 8).map((option, index) => (
                <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                  {option}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(8, 19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default ComplianceAndAssertiveness
