import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@reach/router'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'
import { usePerson, useSteps } from 'src/hooks'

const EmotionalSensitivityAndStability = () => {
  const { person } = usePerson()
  const location = useLocation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const { setNextAction } = useSteps()
  const section = React.useMemo(() => location.pathname.split('/').pop(), [location])

  const options = React.useMemo(
    () =>
      [
        'não teme as coisas que deveria temer',
        'já esteve em situações perigosas porque não estava prestando atenção',
        'poderia ter feito menos coisas estúpidas se sentisse vergonha com mais frequência',
        'não se incomoda com coisas que deveriam lhe incomodar',
        'lida facilmente com falhas',
        'estaria melhor na vida caso fosse mais preocupado',
        'não parece aprender tão bem com seus erros quanto os demais',
        'age com descuido e inapropriadamente sem sentir nenhum remorso',
        'não se preocupa com coisas que deveria lhe incomodar',
        'não tem atenção suficiente aos potenciais perigos futuros',
        'às vezes é muito calmo',
        'muitas vezes é otimista demais',
        'não parece se afetar por críticas, mesmo quando construtivas',
        'muitas vezes corre riscos contraproducentes ou desnecessários',
        'não parece se beneficiar de feedback negativo',
        'é facilmente perturbado ou chateado',
        'está frequentemente irritado ou com raiva',
        'é muito temperamental e emocionalmente instável',
        'é muito autoconsciente para o seu próprio bem',
        'reage desproporcionalmente às vezes',
        'sente vergonha do seu corpo',
        'sente-se magoado frequentemente, mesmo com pequenas coisas',
        'sente-se sem jeito quando apresentado a novas pessoas',
        'sente muito medo e ansiedade',
        'costuma subestimar-se',
        'estressa-se facilmente',
        'resmunga e reclama das coisas',
        'tem dificuldade em se acalmar depois de perder a linha',
        'deixa seus medos impedirem suas realizações',
        'muitas vezes se sente deprimido',
      ].map((option) => `${person?.first_name} ${option}`),
    [person?.first_name],
  )

  const handleSelectOption = (option) => {
    if (isSelectedOption(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option))
    } else {
      if (selectedOptions.length === 10) {
        toast.warn('Tu deve escolher no máximo 10 hábitos')
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const isSelectedOption = (option) => selectedOptions.some((selected) => selected === option)

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptions.length < 3) {
      toast.warn('Para continuar selecione pelo menos 3 hábitos')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.post('faults', selectedOptions, {
      params: {
        section,
      },
    })
  }, [selectedOptions, section])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/faults', { params: { section } })
      .then((response) => {
        setSelectedOptions(response.data.map(({ description }) => description))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [section])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Faltas - Sensibilidade e Estabilidade Emocional
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Ainda como se tu foste{' '}
                <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
                  {person?.third_person_name}
                </Text>{' '}
                falando sobre ti, escolha até 10 hábitos.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Obs: escolha sendo bastante inclusivo, não se apegue à literalidade da descrição. Iremos priorizá-las
                mais tarde.
              </Text>
            </Box>
            <Box>
              {options.slice(0, 8).map((option, index) => (
                <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                  {option}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(8, 19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default EmotionalSensitivityAndStability
