import bgDots1 from 'src/images/bg-dots1.svg'
import bgDots2 from 'src/images/bg-dots2-white.svg'
import bgTY from 'src/images/bg-obrigado.svg'
import { breakpoints } from 'src/styles/theme'
import { Link as GaLink } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { Absolute, Flex, Text } from 'src/components/ui'
import { FaArrowRight, FaCheck } from 'react-icons/fa'

const Link = styled(GaLink)`
  color: #ff5616;
  display: flex;
  align-items: center;
  font-weight: 900;
  text-decoration: none;
  font-size: 16px;
  margin-top: 48px;

  svg {
    margin-left: 16px;
    transition: transform 0.2s linear;
  }

  :hover svg {
    transform: translate(6px, 0);
  }

  @media (min-width: ${breakpoints[0]}) {
    font-size: 24px;
  }
`

const ThankYou = () => (
  <Flex
    alignItems='center'
    backgroundColor='#272727'
    backgroundImage={`url('${bgTY}')`}
    backgroundPosition={['center 130%', 'center top']}
    backgroundRepeat='no-repeat'
    backgroundSize={['100%', '100%']}
    flexDirection='column'
    height='100vh'
    justifyContent='center'
    position='relative'
    width='100vw'
  >
    <Absolute as='img' display={['none', 'block']} right='2vw' src={bgDots1} top={[30, 56, 46]} width={[24, 48]} />
    <Absolute as='img' bottom='5vh' display={['none', 'block']} left='2vw' src={bgDots2} width={[42, 122]} />
    <FaCheck color='#FF5616' size='180' />

    <Text color='#FFFFFF' fontSize={[32, 64]} fontWeight='300' textTransform='uppercase'>
      Email{' '}
      <Text as='span' color='inherit' fontSize='inherit' fontWeight='900'>
        Enviado
      </Text>
    </Text>
    <Text color='#ffffff' fontSize={[16, 32]} fontWeight='900' textAlign='center'>
      Obrigado por participar do mapa biográfico!
    </Text>

    <Link to='/'>
      Voltar para a página principal
      <FaArrowRight />
    </Link>
  </Flex>
)

export default ThankYou
