import React from 'react'

import { Box, Flex, StepsContainer, Text, VideoWrapper } from 'src/components/ui'

const Intro = () => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.fbq != null) {
        window.fbq('track', 'PageView')
      }
    }
  })

  return (
    <StepsContainer>
      <Box flex='1 0 100%' mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Conhecendo{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            o Navio
          </Text>
        </Text>
      </Box>
      <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
        <Box flex='1'>
          <VideoWrapper>
            <iframe allowFullscreen frameBorder='0' src='https://www.youtube.com/embed/39xBTWS9OiE' />
          </VideoWrapper>
        </Box>
        <Box flex='1' maxWidth={['100%', 550, 830]} ml={[0, 48]} mt={[48, 0]}>
          {[
            'Esse é o primeiro passo de uma grande jornada: a tua biografia.',
            'Essa etapa de "Conhecendo o Navio" é justamente o momento de sabermos do que tu és feito(a).',
            'Se pularmos essa etapa pode ser que estejamos tentando atravessar o oceano com uma canoa ou descer um rio com um porta-aviões.',
            'Haverá algumas perguntas bem pessoais, mas somente tu terás acesso. Responda todas com a maior sinceridade e se possível veja o vídeo aqui ao lado.',
            'A respeito do local de nascimento, caso tenha sido em uma cidade muito de interior, pode ser que não haja a opção mapeada na plataforma, então te sugiro colocares a capital mais próxima. Este dado será utilizado para o cálculo do mapa natal.',
            'Ah, e não se preocupe se precisares interromper na metade o processo. Não é o ideal, mas caso tenhas de fechar o navegador, quando voltares e inserir o voucher novamente, ele retornará de onde paraste.',
            'Qualquer dúvida, estou a tua disposição.',
          ].map((text, index) => (
            <Text key={index} fontSize={[16, 16, 24]} lineHeight={['24px', '24px', '36px']} mb={[16, 16, 24]}>
              {text}
            </Text>
          ))}
        </Box>
      </Flex>
    </StepsContainer>
  )
}

export default Intro
