import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@reach/router'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'
import { usePerson, useSteps } from 'src/hooks'

const ComplianceAndAssertiveness = () => {
  const { person } = usePerson()
  const location = useLocation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const { setNextAction } = useSteps()
  const section = React.useMemo(() => location.pathname.split('/').pop(), [location])

  const options = React.useMemo(
    () =>
      [
        'confia nas pessoas',
        'é interessado nas pessoas',
        'está “de bem” com quase todo mundo',
        'tem compaixão dos outros',
        'tem um coração mole',
        'trabalha muito bem com outras pessoas em equipes',
        'se interessa genuinamente sobre o bem-estar dos outros',
        'sabe como confortar os outros',
        'tem amor pelas crianças',
        'faz as pessoas se sentirem à vontade',
        'simpatiza com os sentimentos dos outros',
        'é um bom pacificador',
        'arranja um tempo para servir aos outros',
        'realmente se preocupa com os outros',
        'é um amigo muito leal',
        'motiva-se facilmente',
        'não é facilmente influenciado por emoções que podem dificultar sua vida',
        'tem um bom faro para terceiras intenções',
        'é cético',
        'não se martiriza',
        'não perdoará facilmente se traído ou enganado',
        'sabe dizer quando as pessoas estão fingindo',
        'não é ingênuo',
        'está ciente da maldade no mundo',
        'nem sempre acredita no que as pessoas prometem',
        'não confia nas pessoas com muita facilidade',
        'muito raramente toma vantagem da situação',
        'irá confrontar as pessoas, caso necessário',
        'pode ser exigente quando as circunstâncias pedem',
        'duvida de boas intenções até ver provas dela',
      ].map((option) => `${person?.first_name} ${option}`),
    [person?.first_name],
  )

  const handleSelectOption = (option) => {
    if (isSelectedOption(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option))
    } else {
      if (selectedOptions.length === 10) {
        toast.warn('Tu deve escolher no máximo 10 hábitos')
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const isSelectedOption = (option) => selectedOptions.some((selected) => selected === option)

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptions.length < 3) {
      toast.warn('Para continuar selecione pelo menos 3 hábitos')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.post('virtues', selectedOptions, {
      params: {
        section,
      },
    })
  }, [selectedOptions, section])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/virtues', { params: { section } })
      .then((response) => {
        setSelectedOptions(response.data.map(({ description }) => description))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [section])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Conformidade e Assertividade
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Ainda como se tu foste{' '}
                <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
                  {person?.third_person_name}
                </Text>{' '}
                falando sobre ti, escolha até 10 hábitos.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Obs: escolha sendo bastante inclusivo, não se apegue à literalidade da descrição. Iremos priorizá-las
                mais tarde.
              </Text>
            </Box>
            <Box>
              {options.slice(0, 9).map((option, index) => (
                <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                  {option}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(9, 21).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(21).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default ComplianceAndAssertiveness
