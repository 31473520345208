import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@reach/router'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'
import { usePerson, useSteps } from 'src/hooks'

const ExtroversionAndIntroversion = () => {
  const { person } = usePerson()
  const location = useLocation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const { setNextAction } = useSteps()
  const section = React.useMemo(() => location.pathname.split('/').pop(), [location])

  const options = React.useMemo(
    () =>
      [
        'poderia ter uma vida de festas',
        'sente-se confortável com as pessoas',
        'inicia conversas facilmente',
        'conversa com muitas pessoas diferentes em ocasiões sociais',
        'não se importa de ser o centro das atenções',
        'faz amigos facilmente',
        'pode assumir o comando e liderar',
        'sabe como cativar pessoas',
        'sente-se à vontade com as pessoas',
        'é hábil em lidar com situações sociais',
        'é muitas vezes alegre',
        'faz outras pessoas rirem e se divertirem',
        'fica entusiasmado com novas oportunidades',
        'é alguém divertido de ter ao redor',
        'gosta de convidar pessoas para confraternizar',
        'pode ser um bom ouvinte',
        'não domina as conversas',
        'raramente ergue a voz',
        'não gasta seu dinheiro por capricho',
        'não festeja ou socializa excessivamente',
        'não atrai atenção indevida para si',
        'nem sempre fala de si',
        'não é imponente ou arrogante',
        'fica confortável sozinho',
        'aproveita o tempo na natureza',
        'não sente necessidade de buscar sempre emoção ou novidades',
        'raramente é impulsivo',
        'nem sempre precisa ser o centro das atenções',
        'deixa que outras pessoas tenham os holofotes',
        'pensa antes de agir',
      ].map((option) => `${person?.first_name} ${option}`),
    [person?.first_name],
  )

  const handleSelectOption = (option) => {
    if (isSelectedOption(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option))
    } else {
      if (selectedOptions.length === 10) {
        toast.warn('Tu deve escolher no máximo 10 hábitos')
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const isSelectedOption = (option) => selectedOptions.some((selected) => selected === option)

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptions.length < 3) {
      toast.warn('Para continuar selecione pelo menos 3 hábitos')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.post('virtues', selectedOptions, {
      params: {
        section,
      },
    })
  }, [selectedOptions, section])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/virtues', { params: { section } })
      .then((response) => {
        setSelectedOptions(response.data.map(({ description }) => description))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [section])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Extroversão e Introversão
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Agora como se tu foste{' '}
                <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
                  {person?.third_person_name}
                </Text>{' '}
                falando sobre ti, escolha até 10 hábitos.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Obs: escolha sendo bastante inclusivo, não se apegue à literalidade da descrição. Iremos priorizá-las
                mais tarde.
              </Text>
            </Box>
            <Box>
              {options.slice(0, 8).map((option, index) => (
                <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                  {option}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(8, 19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(19).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default ExtroversionAndIntroversion
