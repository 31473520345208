import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useSteps } from 'src/hooks'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'

const HabitSelection = () => {
  const [options, setOptions] = React.useState([])
  const [selectedOptionsCount, setSelectedOptions] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(true)

  const { setNextAction } = useSteps()

  const handleSelectOption = (option) => {
    if (selectedOptionsCount >= 9 && !option.is_chosen) {
      return toast.warn('Tu deve escolher no máximo 9 virtudes')
    }

    const optionIndex = options.findIndex(({ id }) => id === option.id)

    options[optionIndex] = { ...option, is_chosen: !option.is_chosen }

    setOptions([...options])
  }

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptionsCount < 6) {
      toast.warn('Para continuar selecione pelo menos 6 virtudes')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.put(
      '/virtues',
      options.map(({ id, is_chosen }) => ({ id, is_chosen })),
    )
  }, [options, selectedOptionsCount])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/virtues')
      .then((response) => {
        setOptions(response.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  React.useEffect(() => {
    setSelectedOptions(options.filter(({ is_chosen }) => is_chosen).length)
  }, [options, setSelectedOptions])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Seleção de hábito
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Agora tu irás responder da forma mais pessoal possível. Escolha entre 6 e 9 virtudes que tu gostarias de
                performar melhor.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Dica: selecione as mais importantes ou mais típicas da tua personalidade.
              </Text>
            </Box>
            <Box>
              {options.slice(0, options.length / 3).map((option) => (
                <Option key={option.id} checked={option.is_chosen} onClick={() => handleSelectOption(option)}>
                  {option.description}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(options.length / 3, (options.length / 3) * 2).map((option, index) => (
              <Option key={option.id} checked={option.is_chosen} onClick={() => handleSelectOption(option)}>
                {option.description}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice((options.length / 3) * 2).map((option, index) => (
              <Option key={option.id} checked={option.is_chosen} onClick={() => handleSelectOption(option)}>
                {option.description}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default HabitSelection
