import api from 'src/providers/api'
import React from 'react'
import { toast } from 'react-toastify'
import { useLocation } from '@reach/router'
import { Box, Flex, Loader, Option, StepsContainer, Text } from 'src/components/ui'
import { usePerson, useSteps } from 'src/hooks'

const ZealAndCarelessness = () => {
  const { person } = usePerson()
  const location = useLocation()

  const [isLoading, setIsLoading] = React.useState(true)
  const [selectedOptions, setSelectedOptions] = React.useState([])
  const { setNextAction } = useSteps()
  const section = React.useMemo(() => location.pathname.split('/').pop(), [location])

  const options = React.useMemo(
    () =>
      [
        'está sempre preparado',
        'consegue se concentrar por um longo tempo sem se distrair',
        'é exigente no seu trabalho',
        'não para até que tudo esteja perfeito',
        'faz as coisas de acordo com um plano',
        'busca eficiência e economia',
        'costuma tentar terminar suas tarefas imediatamente',
        'julga que trabalho árduo deve ser recompensado bem',
        'adora ordem e previsibilidade ',
        'faz planos e os cumpre',
        'presta atenção aos detalhes',
        'é extremamente confiável',
        'sempre chega cedo ou em ponto em seus compromissos',
        'é muito objetivo',
        'cumpre com o que promete',
        'tem uma atitude descontraída ',
        'pode ser facilmente espontâneo e aproveitar o momento',
        'não é crítico',
        'não superestima suas expectativas',
        'não costuma ser perfeccionista',
        'não sente que sempre tem que estar no controle',
        'não costuma impor um conjunto rígido de padrões a outras pessoas',
        'não se incomoda quando as coisas não saem conforme o planejado',
        'vive o momento',
        'não fica muito envolvido no seu trabalho',
        'raramente é incomodado pela desordem',
        'nunca faz mais trabalho do que o necessário',
        'não é uma pessoa muito tensa',
        'sabe ser leniente',
        'não perde seu tempo pensando em pequenos detalhes',
      ].map((option) => `${person?.first_name} ${option}`),
    [person?.first_name],
  )

  const handleSelectOption = (option) => {
    if (isSelectedOption(option)) {
      setSelectedOptions(selectedOptions.filter((selected) => selected !== option))
    } else {
      if (selectedOptions.length === 10) {
        toast.warn('Tu deve escolher no máximo 10 hábitos')
      } else {
        setSelectedOptions([...selectedOptions, option])
      }
    }
  }

  const isSelectedOption = (option) => selectedOptions.some((selected) => selected === option)

  const handleSubmit = React.useCallback(async () => {
    if (selectedOptions.length < 3) {
      toast.warn('Para continuar selecione pelo menos 3 hábitos')

      return Promise.reject(new Error('Not enough habits selected'))
    }

    await api.post('virtues', selectedOptions, {
      params: {
        section,
      },
    })
  }, [selectedOptions, section])

  React.useEffect(() => {
    setNextAction(() => handleSubmit)

    return () => {
      setNextAction(null)
    }
  }, [setNextAction, handleSubmit])

  React.useEffect(() => {
    api
      .get('/virtues', { params: { section } })
      .then((response) => {
        setSelectedOptions(response.data.map(({ description }) => description))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [section])

  return (
    <StepsContainer>
      <Box mb={[48]}>
        <Text as='h1' color='#D2D2D2' fontSize={[32, 64, 90]} fontWeight='300' textTransform='uppercase'>
          Virtudes{' '}
          <Text as='span' color='#272727' fontSize='inherit' fontWeight='900'>
            E Faltas
          </Text>
        </Text>
        <Text as='h2' color='#D2D2D2' fontSize={[24, 32]}>
          Virtudes - Zelo e Descuido
        </Text>
      </Box>

      {isLoading ? (
        <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' justifyContent='center'>
          <Loader size={64} />
        </Flex>
      ) : (
        <Flex flex='1 0 100%' flexDirection={['column', 'row']}>
          <Box flex='1'>
            <Box mb={32}>
              <Text color='#303030' fontSize={24} fontWeight='900' mb={16}>
                Ainda como se tu foste{' '}
                <Text as='span' color='#FF5616' fontSize='inherit' fontWeight='inherit'>
                  {person?.third_person_name}
                </Text>{' '}
                falando sobre ti, escolha até 10 hábitos.
              </Text>
              <Text color='#D2D2D2' fontSize={16}>
                Obs: escolha sendo bastante inclusivo, não se apegue à literalidade da descrição. Iremos priorizá-las
                mais tarde.
              </Text>
            </Box>
            <Box>
              {options.slice(0, 8).map((option, index) => (
                <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                  {option}
                </Option>
              ))}
            </Box>
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(8, 20).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>

          <Box flex='1' ml={[0, 48]} mt={[0]}>
            {options.slice(20).map((option, index) => (
              <Option key={index} checked={isSelectedOption(option)} onClick={() => handleSelectOption(option)}>
                {option}
              </Option>
            ))}
          </Box>
        </Flex>
      )}
    </StepsContainer>
  )
}
export default ZealAndCarelessness
