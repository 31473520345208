import api from '../providers/api'
import { PersonProvider } from 'src/hooks/usePerson'
import SEO from '../components/SEO'
import { useSessionStorage } from '../hooks'
import { EmailConfirmation, Intro, Profile, ThankYou } from '../components/pages'
import {
  FaultsActionPlan,
  FaultsComplianceAndAssertiveness,
  FaultsCreativeObjective,
  FaultsEmotionalSensitivityAndStability,
  FaultsExtroversionAndIntroversion,
  FaultsHabitPrioritization,
  FaultsHabitSelection,
  FaultsZealAndCarelessness,
} from 'src/components/pages/faults'
import { Flex, Loader, StepsNavigation } from 'src/components/ui'
import React, { useEffect, useState } from 'react'
import { Redirect, Router } from '@reach/router'
import useSteps, { StepsProvider } from 'src/hooks/useSteps'
import {
  VirtuesActionPlan,
  VirtuesComplianceAndAssertiveness,
  VirtuesCreativeObjective,
  VirtuesEmotionalSensitivityAndStability,
  VirtuesExtroversionAndIntroversion,
  VirtuesHabitPrioritization,
  VirtuesHabitSelection,
  VirtuesZealAndCarelessness,
} from 'src/components/pages/virtues'

const App = () => {
  const [voucher] = useSessionStorage('voucher')
  const [isVoucherValid, setIsVoucherValid] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { currentStep } = useSteps

  useEffect(() => {
    if (voucher) {
      const validateVoucher = async () => {
        setIsLoading(true)
        try {
          await api.get(`/vouchers/${voucher}`)

          setIsVoucherValid(true)
        } catch (e) {
          setIsVoucherValid(false)
        } finally {
          setIsLoading(false)
        }
      }

      validateVoucher()
    }
  }, [voucher, currentStep])

  if (!voucher) {
    return <Redirect noThrow to='/' />
  }

  if (isLoading) {
    return (
      <Flex alignItems='center' color='#d2d2d2' flex='1 0 100%' height='100vh' justifyContent='center' width='100vw'>
        <Loader size={64} />
      </Flex>
    )
  }

  if (!isVoucherValid) {
    return <Redirect noThrow to='/' />
  }

  return (
    <StepsProvider>
      <PersonProvider>
        <SEO title='Conhecendo o Navio' />
        <Router basepath='/conhecendo-o-navio'>
          <Intro path='/' />
          <Profile path='/perfil' />
          <FaultsExtroversionAndIntroversion path='/faltas/extroversao-e-introversao' />
          <FaultsCreativeObjective path='/faltas/criativo-objetivo' />
          <FaultsZealAndCarelessness path='/faltas/zelo-e-descuido' />
          <FaultsEmotionalSensitivityAndStability path='/faltas/sensibilidade-e-estabilidade-emocional' />
          <FaultsComplianceAndAssertiveness path='/faltas/conformidade-e-assertividade' />
          <FaultsHabitSelection path='/faltas/selecao-de-habito' />
          <FaultsHabitPrioritization path='/faltas/priorizacao-de-habito' />
          <FaultsActionPlan path='/faltas/plano-de-acao' />

          <VirtuesExtroversionAndIntroversion path='/virtudes/extroversao-e-introversao' />
          <VirtuesCreativeObjective path='/virtudes/criativo-objetivo' />
          <VirtuesZealAndCarelessness path='/virtudes/zelo-e-descuido' />
          <VirtuesEmotionalSensitivityAndStability path='/virtudes/sensibilidade-e-estabilidade-emocional' />
          <VirtuesComplianceAndAssertiveness path='/virtudes/conformidade-e-assertividade' />
          <VirtuesHabitSelection path='/virtudes/selecao-de-habito' />
          <VirtuesHabitPrioritization path='/virtudes/priorizacao-de-habito' />
          <VirtuesActionPlan path='/virtudes/plano-de-acao' />

          <EmailConfirmation path='/confirmacao-email' />

          <ThankYou path='/obrigado' />
        </Router>
        <StepsNavigation />
      </PersonProvider>
    </StepsProvider>
  )
}

export default App
